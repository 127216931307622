<template>
  <div class="d-flex align-center justify-center alert-status" @click="closeAlert">
    <div class="numberSection text-center d-flex align-center justify-center flex-column white pa-6" @click.stop>
      <v-img
        :src="require('../assets/' + getAlertStatus.img)"
        contain
        width="40"
        height="40"
        class="mx-auto"
      />
      <h4 v-if="getAlertStatus.message" class="dark--text verifySuccess">
        {{ getAlertStatus.message }}
      </h4>
      <h4 v-else class="dark--text verifySuccess">
        {{ getAlertStatus.error }}
      </h4>
      <v-btn
        @click="close"
        color="primary"
        class="text-none"
        depressed
      >
        {{ getAlertStatus.buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AlertStatus',
  data: () => ({}),
  computed: {
    ...mapGetters(['getAlertStatus'])
  },
  methods: {
    ...mapActions(['closeAlert']),
    close () {
      this.$emit('close', this.getAlertStatus.callback)
      this.closeAlert()
    }
  }
}
</script>

<style scoped lang="scss">
.alert-status {
  position: fixed;
  z-index: 9999;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: #00000080;
}

.numberSection {
  min-width: min(512px, 98vw);
  min-height: 156px;
  border-radius: 8px;
  gap: 24px;
}

.verifySuccess {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.v-text-field::v-deep {
  &:not(.error--text) {
    .v-input__slot fieldset {
      border: 1px solid #e3e3e3 !important;
    }

    &.v-input--is-dirty {
      .v-input__slot fieldset {
        border: 1px solid #27ae60 !important;
      }

      &.v-input--is-focused {
        .v-input__slot fieldset {
          border: 1px solid #5758b0 !important;
        }
      }
    }
  }
}

.v-btn--outlined.light--text {
  border: 1px solid #d6d8e3;
  width: 100%;
}
</style>
