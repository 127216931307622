<template>
  <div class="d-block">
    <v-app-bar app color="primary" elevation="0" height="72" dark>
      <v-app-bar-nav-icon
        @click="$vuetify.breakpoint.mdAndUp && $route.name === 'Settings' ? toHome() : $store.commit('changeToggleSidebarFunction')"
        class="d-none d-sm-block"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-none d-sm-block font-weight-bold">
        <a href="http://www.freefone.app/"><v-img :src="require('../assets/png/new-logo.png')" width="100" contain /></a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-autocomplete
        v-if="!isAdmin"
        v-model="model"
        :items="calls"
        :loading="isLoading"
        :search-input.sync="search"
        :filter="customFilter"
        ref="search"
        outlined
        dense
        prepend-inner-icon="mdi-magnify"
        append-icon=""
        hide-details
        hide-no-data
        hide-selected
        solo
        class="muted--text navbarSearch"
        light
        color="muted"
        return-object
        item-value="uuid"
        :label="$vuetify.breakpoint.smAndUp ? 'Search numbers' : 'Enter a name or number'"
      >
        <template v-slot:selection>{{ getCallName(model) }}</template>
        <template v-slot:item="data">
          <v-list-item
            v-if="data.item"
            class="numbers-list__item"
            @click="setCall(data.item)"
          >
            <v-list-item-avatar color="primary">
              <v-icon color="#fff">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="call-name d-flex align-center justify-space-between">
                <span class="font-weight-regular">{{ getCallName(data.item) }}</span>
                <span class="fz-12 mt-1" v-if="$vuetify.breakpoint.mdAndUp">{{ data.item.duration }}s</span>
              </v-list-item-title>
              <v-list-item-subtitle v-if="data.item" class="px-0 d-flex align-center">
                <template v-if="isDesktop">
                  <div>
                    <v-icon v-if="data.item.is_spam || $route.name === 'Spam'" color="red" size="16px">mdi-call-missed</v-icon>
                    <v-icon v-else-if="data.item.direction === 'out'" color="red" size="16px">mdi-call-made</v-icon>
                    <v-icon v-else color="green" size="16px">mdi-call-received</v-icon>
                  </div>
                  <span v-if="data.item.is_spam" class="ml-1 red--text fz-12">Suspected spam <span class="text--darken-2 grey--text"> • {{ formatDateShort(data.item.createdAt) }}</span></span>
                  <span v-else class="ml-1 fz-12">{{ formatDate(data.item.createdAt) }}</span>
                </template>
                <span v-else>
                  +{{ data.item.direction === 'out' ? data.item.dnis : data.item.ani }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-spacer></v-spacer>

      <v-btn icon @click="$router.push({ name: $route.name === 'Settings' ? 'Calls' : 'Settings' })">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/resize.mixin'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'Navbar',
  mixins: [resizeMixin],
  data: () => ({
    isLoading: false,
    items: [],
    model: null,
    search: null,
    searchText: '',
    calls: []
  }),
  computed: {
    isDesktop () {
      return this.$vuetify.breakpoint.smAndUp
    },
    user () {
      return this.$store.state.user.userProfile
    },
    isAdmin () {
      return this.user && this.user.is_admin
    }
  },
  created () {
    this.$store.commit('changeToggleSidebarFunction', this.$vuetify.breakpoint.mdAndUp)
    !this.isAdmin && this.loadCalls()
  },
  mounted () {
    const self = this
    window.addEventListener('storage', function (event) {
      if (event.key === 'currentUser' && !event.newValue) {
        self.logout()
      }
    })
  },
  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      if (val) this.$store.commit('changeToggleSidebarFunction', false)
    },
    '$vuetify.breakpoint.mdAndUp' (val) {
      if (val) this.$store.commit('changeToggleSidebarFunction', true)
    }
  },
  methods: {
    ...mapMutations('calls', ['setCallItem']),
    ...mapActions('calls', ['getCalls']),
    ...mapActions(['logout']),
    async loadCalls () {
      try {
        const params = {
          orderType: 'DESC',
          orderBy: 'createdAt',
          take: 9999
        }
        const data = await this.getCalls({ dir: 'all', params })
        this.calls = data.data
      } catch (err) {
        throw new Error(err)
      }
    },
    customFilter (item, queryText, itemText) {
      this.searchText = queryText
      return (item.direction === 'in' && (item.caller_first_name?.toLowerCase().includes(queryText.toLowerCase()) ||
                                    item.caller_last_name?.toLowerCase().includes(queryText.toLowerCase()) ||
                                    item.ani.toString().includes(queryText.toString()))) ||
        (item.direction === 'out' && (item.callee_first_name?.toLowerCase().includes(queryText.toLowerCase()) ||
                                    item.caller_last_name?.toLowerCase().includes(queryText.toLowerCase()) ||
                                    item.dnis.toString().includes(queryText.toString())))
    },
    hasCallName (callItem) {
      return (callItem.direction === 'in' && (callItem.caller_first_name || callItem.caller_last_name)) ||
        (callItem.direction === 'out' && (callItem.callee_first_name || callItem.caller_last_name))
    },
    getCallName(item) {
      if (item.direction === 'in' || !item.direction) {
        return !item.caller_first_name && !item.caller_last_name ? item.ani
          : `${item.caller_first_name ? item.caller_first_name : ''} ${item.caller_last_name ? item.caller_last_name : ''}`
      } else {
        const callerFirst = item.callee_first_name
        const callerLast = item.callee_last_name

        if (callerFirst && callerLast) return `${callerFirst + ' ' + callerLast}`
        if (callerFirst && !callerLast) return callerFirst
        if (!callerFirst && callerLast) return callerLast
        if (!callerFirst && !callerLast) return item.dnis
      }
    },
    setCall (item) {
      this.model = item
      this.setCallItem(item)
      this.$refs.search.blur()
      if (this.$route.name !== 'Calls') this.$router.push({ name: 'Calls', params: { redirect: true } }).catch(() => {})
    },
    toHome () {
      try {
        this.$router.push({ name: this.isAdmin ? 'AdminUsers' : 'Calls' }).catch(() => {})
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
.navbarSearch {
  .mdi-menu-down {
    display: none;
  }
}
.navbarSearch::v-deep .v-input__control .v-input__prepend-inner {
  padding-right: 13px;
  padding-top: 2px;
}
.numbers-list__item {
  cursor: pointer;
  &:hover {
    background: #5758B020;
  }
}
</style>
